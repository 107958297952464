import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import Navbar from '../components/Navbar'

export const IndexPageTemplate = ({
  logo, 
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
    <div style={{ display: "flex", flex:1, height: "100%", padding: "20px"}}>
      <div className="container" style={{ flex: 1, display: "flex", flexDirection: "column"}}>
        <Navbar />
        <div className="mainframe" style={{flex: 1, justifyContent: "center", alignContent: "center"}}>
          <div className="slog">
            <img src="./Slice%201@2x.png" alt="Slice%201" width="393" height="122" />
          </div>
          <div className="about">
            Find out more <Link to="/about">about us</Link>, the <Link to="/services">services</Link> we offer, our <Link to="/blog">blog</Link>, the <Link to="/team">team</Link> or feel free to <a href="mailto:info@ipf.gr">get in touch</a>.
		      </div>
        </div>
        <div className="footer" style={{flex: 0}}>
          <span>IPF Investment & Consulting SA, Registered in Greece, <a href="isologismos2015.pdf">Financial Report 2022</a></span>
        </div>
      </div>
    </div>
  )

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { logoImage } = data.allFile

  return (
    <Layout header={false} footer={false}>
      <IndexPageTemplate
        logo={logoImage}
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
    allFile(filter: {name: {eq: "logos@2x"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`
